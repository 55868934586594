import { ReactElement } from 'react';

import type { GetServerSideProps } from 'next';
import dynamic from 'next/dynamic';
import { Toaster } from 'react-hot-toast';
import Cookies from 'universal-cookie';

import Navbar from '@/components/layouts-and-navs/Navbar';
import Spinner from '@/components/Spinner';

const DynamicEmbeddedWidget = dynamic(() => import('@dynamic-labs/sdk-react-core').then((module) => module.DynamicEmbeddedWidget), {
  loading: () => (
    <div className='size-16 mx-auto'>
      <Spinner />
    </div>
  ),
  ssr: false,
});

const Login = () => {
  return (
    <div className='items-center w-full min-h-screen bg-jet-black flex flex-col justify-center overflow-x-hidden'>
      <Navbar otherLinksHide={true} />
      {/* <img src='/images/pink-login.svg' alt='pink graph' className='absolute top-0 right-[10%] z-[22]' /> */}
      {/* <img src='/images/purple-login.svg' alt='purple graph' className='absolute sm:left-[5%] bottom-0' /> */}
      <div className='flex flex-col justify-center items-center h-full w-full relative max-w-[1300px]'>
        <div className='mt-10 min-w-[300px] min-[350px]:min-w-[350px] max-w-[468px] w-full sm:w-max px-4 sm:px-0 relative z-[22]'>
          <DynamicEmbeddedWidget background='default' />
          <Toaster />
        </div>
      </div>
    </div>
  );
};

export const getServerSideProps = (async (context) => {
  const cookies = new Cookies(context.req.headers.cookie);
  const isAuth = cookies.get('_token');

  if (isAuth) {
    return {
      redirect: {
        destination: '/portfolio',
        permanent: false,
      },
    };
  }

  return {
    props: {},
  };
}) satisfies GetServerSideProps;

Login.getLayout = (page: ReactElement) => page;

export default Login;
